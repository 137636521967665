<template>
	<div class="class-page">
		<div class="page-title mb20">分类列表</div>
		<div class="mb20 flex flex-end"><a-button type="primary" @click="showAdd">新增</a-button></div>
		<div class="equipment-table">
			<a-table 
				:rowKey="(item,index) => {return index}" 
				:loading="dataLoading" 
				:columns="columns"
				:data-source="list" 
				:bordered="true" 
				@change="listChange"
				:pagination="{hideOnSinglePage: true,  total: total , current: current}">
				<template slot="operation" slot-scope="index, item">
					<div class="control-box">
						<a-button size="small" type="primary" @click="showEdit(item)">
							编辑
						</a-button>
						<a-popconfirm
						    title="确认删除该分类"
						    ok-text="是"
						    cancel-text="否"
						    @confirm="deleteClass(item)"
						  >
							<a-button size="small" type="danger">
								删除
							 </a-button>
						  </a-popconfirm>
					</div>
				</template>
			</a-table>
		</div>
		<a-modal v-model="addShow" 
		title="添加分类"
		:confirmLoading="addIng"
		:cancelText="'取消'"
		:okText="'提交'"
		@ok="submitAdd">
			<a-form-model>
				<a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" label="分类名称:" prop="cat_name">
					<a-input v-model="addClass.cat_name"/>
				</a-form-model-item>
				<a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" label="排序:" prop="sort_no">
					<a-input  v-model="addClass.sort_no"/>
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
	let columns = [{
		title:'ID',
		key:'id',
		dataIndex:'id'
	},{
		title:'分类名称',
		key:'cat_name',
		dataIndex:'cat_name'
	},{
		title:'排序',
		key:'sort_no',
		dataIndex:'sort_no'	
	},{
		title: '操作',
		dataIndex: 'operation',
		width: '128px',
		scopedSlots: { customRender: 'operation' }
	}]
	export default {
		data() {
			return {
				columns:columns,
				dataLoading: false,
				current:1,
				total: 0,
				list:[],
				addShow:false,
				addIng: false,
				addClass: {
					cat_name: '',
					sort_no: ''
				},
				editId: '',
				isEdit: false
			}
		},
		computed: {},
		created() {
			
		},
		mounted() {
			// this.list.push({
			// 	id:31,
			// 	name:'学习强国',
			// 	index:1
			// })
			this.getData()
		},
		methods:{
			getData() {
				let t = this
				t.dataLoading = true
				t.$get({
					port: 'a',
					url: 'fenleiList',
					data: {page: t.current}
				}).then(res => {
					t.dataLoading = false
					let { code, data, msg } = res
					if (code == 0) {
						t.list = data.list
						t.total = data.total
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
					t.dataLoading = true
				})
			},
			listChange(e) {
				this.current = e.current
				this.getData()
			},
			deleteClass(item) {
				let t = this
				t.$get({
					port: 'a',
					url: 'fenleiDel',
					data: {fenlei_id: item.fenlei_id}
				}).then(res => {
					let { code, data, msg } = res
					if (code == 0) {
						t.$message.success(msg, 1.5)
						t.getData()
					} else {
						t.$message.error(msg, 1.5)
					}
				}).catch(err => {
				
				})
			},
			showAdd(){
				this.addShow = true
				this.isEdit = false
				this.addClass.cat_name = ''
				this.addClass.sort_no = ''
			},
			showEdit(item) {
				this.addShow = true
				this.isEdit = true
				this.editId = item.fenlei_id
				this.addClass.cat_name = item.cat_name
				this.addClass.sort_no = item.sort_no
			},
			submitAdd() {
				let t = this
				t.addIng = true
				if(t.isEdit) {
					t.$post({
						port: 'a',
						url: 'fenleiEdit',
						data: {
							fenlei_id: t.editId,
							cat_name: t.addClass.cat_name,
							sort_no: t.addClass.sort_no
						}
					}).then(res => {
						t.addIng = false
						let { code, data, msg } = res
						if (code == 0) {
							t.$message.success(msg, 1.5)
							t.addShow = false
							t.getData()
						} else {
							t.$message.error(msg, 1.5)
						}
					}).catch(err => {
						t.addIng = false
					})
				}else{
					t.$post({
						port: 'a',
						url: 'fenleiAdd',
						data: t.addClass
					}).then(res => {
						t.addIng = false
						let { code, data, msg } = res
						if (code == 0) {
							t.$message.success(msg, 1.5)
							t.addShow = false
							t.getData()
						} else {
							t.$message.error(msg, 1.5)
						}
					}).catch(err => {
						t.addIng = false
					})
				}
				
			}
		}
	}
</script>

<style lang="less">
</style>
